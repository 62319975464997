/***********************************


トップ News & Topics カルーセル


************************************/

function newsCarousel() {
	let $carousels = $(".js-newsCarousel");
	let slickArr = [];
	let currentSlider = 0;
	
	if ($carousels.length > 0) {
		$carousels.each( (index,el) => {
			slickArr[index] = $(el).slick({
				slidesToShow: 4,
				slidesToScroll: 4,
				dots: false,
				infinite: true,
				arrows: false,
				responsive: [
					{
						breakpoint: SP_WIDTH + 1,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					}
				]
			});
		});
		
		$("#jsNewsCarouselArrow a").on("click", event => {
			event.preventDefault();
			let $target = getCurrentSlider(slickArr);
			// console.log($target);
			if ($(event.currentTarget).hasClass("toPrev")) {
				$target.slick("slickPrev");
			} else {
				$target.slick("slickNext");
			}
		});
	}
}
//現在タブで表示されているスライダーを取得
function getCurrentSlider(slickArr){
	let $currentEl = null;
	slickArr.forEach( (element) => {
		if(element.parent().hasClass("is-current")){
			$currentEl = element;
		}
	});
	return $currentEl;
}