/***********************************


スムーススクロール


************************************/

class HashScroll {
	constructor() {
		this.offset = $("#Header").height() + 1;
		this.anchor = $(
			'a[href^="#"]:not(.noScroll),[data-scroll-target^="#"]'
		);
		this.defSpeed = 400;
	}
	init() {
		this.onClick();
		if (location.hash) {
			this.onHash;
		}
	}
	onClick() {
		this.anchor.on("click", event => {
			event.preventDefault();
			let href =
				$(event.currentTarget).attr("href") ||
				$(event.currentTarget).attr("data-scroll-target");
			let $target = $(href === "#" || href === "" ? "html" : href);
			if ($target.length > 0) {
				this.animateScroll($target);
			}
		});
	}
	onHash() {
		setTimeout(() => {
			let $target = $(location.hash);
			if ($target.length > 0) {
				this.animateScroll($target, 0);
			}
			return false;
		}, 10);
	}
	animateScroll($target, speed) {
		speed = typeof speed !== "undefined" ? speed : this.defSpeed;
		let position = 0;
		if ($target && $target.length > 0) {
			position = $target.offset().top - this.offset;
			$("html,body")
			.stop()
			.animate({ scrollTop: position }, speed, "swing");
		}
	
		return false;
	}
}

/***********************************


overflow:scroll内の要素にスクロール

<a href="{selector(ID)}" data-box="{selector}"></a>
************************************/
/**
 * @constructor
 * @extends HashScroll
 */
class BoxScroll extends HashScroll {
	constructor() {
		super();
		this.anchor = $('a[href^="#"][data-box]');
	}
	init() {
		this.anchor.on("click", event => {
			event.preventDefault();
			let $targetBox = $($(event.currentTarget).data("box"));
			let href = $(event.currentTarget).attr("href");
			let $target;
	
			//overflow:scrollでなければ通常のスクロール
			switch ($targetBox.css("overflow")) {
				case "scroll":
					//Box内スクロール
					$target = $targetBox.find(href);
					this.animateBoxScroll($target, $targetBox);
					break;

				default:
					 //HashScroll.animateScroll();
					$target = $(href);
					this.animateScroll($target);
					break;
			}
		});
	}
	animateBoxScroll($target, $targetBox, speed) {
		speed = typeof speed !== "undefined" ? speed : this.defSpeed;

		if ($target.length > 0) {
			// let boxPosition = $targetBox.offset().top - this.offset;
			// $("html,body")
			// 	.stop()
			// 	.animate({ scrollTop: boxPosition }, speed, "swing");

			var position = $target.position().top - $targetBox.position().top;
			$targetBox.stop().animate({
				scrollTop: $targetBox.scrollTop() + position
			});
		}
		return false;
	}
}
