/***********************************


スマホ ドロワーメニュー

ボタン： data-drawer-btn="ID";
ドロワー： data-drawer-content="ID"

・IDで対応したドロワーを表示/非表示
・Open時にボタン・ドロワーの両方に"open"class追加


************************************/

class Drawer {
	constructor() {
		this.btnAttr = "data-drawer-btn";
		this.contentAttr = "data-drawer-content";
		this.openClass = "open";
		this.speed = 200;
	}
	init() {
		this.$drawerBtns = $(`[${this.btnAttr}]`);
		this.$drawers = $(`[${this.contentAttr}]`);
		if (this.$drawerBtns) {
			this.$drawers.hide();
			this.onDrawerBtnClick();
			this.onCloseDomClick();
		}
	}
	onDrawerBtnClick() {
		this.$drawerBtns.on("click", e => {
			e.preventDefault();
			let $currentBtn = $(e.currentTarget);
			let targetID = $currentBtn.attr(this.btnAttr);
			if ($currentBtn.hasClass(this.openClass)) {
				this.close(targetID);
			} else {
				this.closeAll();
				this.open(targetID);
			}
		});
	}
	onCloseDomClick() {
		this.$drawers.on("click", e => {
			if (
				e.target.classList.contains("wrap") ||
				e.target.tagName == "A"
			) {
				let targetID = $(e.currentTarget).attr(this.contentAttr);
				this.close(targetID);
			}
		});
	}
	closeAll() {
		this.$drawerBtns.removeClass(this.openClass);
		this.$drawers.removeClass(this.openClass).slideUp(this.speed);
	}
	close(id) {
		if (id) {
			$(`[${this.btnAttr}=${id}]`).removeClass(this.openClass);
			$(`[${this.contentAttr}=${id}]`)
				.removeClass(this.openClass)
				.slideUp(this.speed);
		}
	}
	open(id) {
		if (id) {
			$(`[${this.btnAttr}=${id}]`).addClass(this.openClass);
			$(`[${this.contentAttr}=${id}]`)
				.addClass(this.openClass)
				.slideDown(this.speed);
		}
	}
}
