/***********************************


タブメニュー

ナビコンテナ: data-tab-nav data-tab-id="tabID"
ナビボタン： href="#id" data-tab-btn
ターゲットコンテナ： data-tab-panels data-tab-id="tabID"
ターゲット： id="id" data-tab-panel

・IDで対応したターゲットを表示/非表示
・Open時にボタン・ターゲットの両方に"is-current"class追加


************************************/
class Tab {
	constructor() {
		this.tabNav = document.querySelectorAll("[data-tab-nav]");
		this.currentClass = "is-current";
		this.init();
	}
	init() {
		this.onClickEvent();
	}
	onClickEvent() {
		Array.prototype.slice.call(this.tabNav).forEach(tab => {
			let anchors = tab.querySelectorAll("[data-tab-btn]");
		
			Array.prototype.slice.call(anchors).forEach(anchor => {
				anchor.addEventListener("click", event => {
					event.preventDefault();
					let tabID = tab.getAttribute("data-tab-id");
					let targetID = anchor.getAttribute("href");
					if (targetID && document.querySelector(targetID)) {
						this.change(tabID, targetID);
					}
				});
			});
		});
	}
	change(tabID, targetID) {
		Array.prototype.slice
			.call(document.querySelectorAll(`[data-tab-id=${tabID}]`))
			.forEach(element => {
				if (element.hasAttribute("data-tab-nav")) {
					let anchors = element.querySelectorAll("[data-tab-btn]");
					Array.prototype.slice.call(anchors).forEach(anchor => {
						if (anchor.getAttribute("href") == targetID) {
							anchor.classList.add(this.currentClass);
						} else {
							anchor.classList.remove(this.currentClass);
						}
					});
				} else if (element.hasAttribute("data-tab-panels")) {
					let targetIdStr = targetID.replace("#", "");
					let panels = element.querySelectorAll("[data-tab-panel]");

					Array.prototype.slice.call(panels).forEach(panel => {
						if (panel.id === targetIdStr) {
							panel.classList.add(this.currentClass);
						} else {
							panel.classList.remove(this.currentClass);
						}
					});
				}
			});
	}
}
