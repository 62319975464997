"use strict";
/***********************************

module 読み込み

************************************/
/**
 * スマホドロワーメニュー
 * class Drawer
 */
// @codekit-prepend "module/drawer.js";

/**
 * タブメニュー
 * class Tab
 */
// @codekit-prepend "module/tabmenu.js";

/**
 * スクロール
 * class HashScroll
 */
// @codekit-prepend "module/hashScroll.js";

/**
 * トップ メインビジュアル
 * class IndexSlider
 */
// @codekit-prepend "module/index-slider.js";

/**
 * トップ News & Topics カルーセル
 * function newsCarousel()
 */
// @codekit-prepend "module/index-newsCarousel.js";

/**
 * 下層ページ ページタイトルのスクロール
 * class MainTitleScroll
 */
// @codekit-prepend "module/main-title-scroll.js";

/**
 * Google Map
 * class AccessMap
 */
// @codekit-prepend "module/map.js";

/***********************************

メイン

************************************/

const SP_WIDTH = 768;

$(document).ready(function() {
	
	if(document.documentElement.getAttribute("lang") === "en" ){
		//英語版共通ファイル
		$("#Header").load("/en/assets/template/header.html");
		$("#Footer").load("/en/assets/template/footer.html");
		$("#SPNavi").load("/en/assets/template/spnavi.html", function() {
			//スマホ ドロワー
			window.drawer = new Drawer();
			drawer.init();
		});
	} else if(document.documentElement.getAttribute("lang") === "zh-cn" ) {
		//中国語版
		$("#Header").load("/cn/assets/template/header.html");
		$("#Footer").load("/cn/assets/template/footer.html");
		$("#SPNavi").load("/cn/assets/template/spnavi.html", function() {
			window.drawer = new Drawer();
			drawer.init();
		});
	} else {
		//日本語版
		$("#Header").load("/assets/template/header.html");
		$("#Footer").load("/assets/template/footer.html");
		$("#SPNavi").load("/assets/template/spnavi.html", function() {
			window.drawer = new Drawer();
			drawer.init();
		});
	}
	
	//トップ メインビジュアル
	window.addEventListener("load", () => {	
		if (document.getElementById("jsIndexSlider")) {
			const indexSlider = new IndexSlider();
		}
	});

	//ページ内スクロール
	const hashScroll = new HashScroll();
	hashScroll.init();
	//Box内スクロール
	const boxScroll = new BoxScroll();
	boxScroll.init();

	//タブメニュー
	const tab = new Tab();
	
	//トップ News & Topics カルーセル
	if ($(".js-newsCarousel").length > 0) {
		newsCarousel();
	}
	//トップ 採用情報バックグラウンド
	if (document.getElementById("jsBgSlide")) {
		$("#jsBgSlide").slick({
			accessibility: false,
			arrows: false,
			infinite: true,
			fade: true,
			speed: 1800,
			autoplay: true,
			autoplaySpeed: 4000
		});
	}
	//下層タイトルスクロール
	if (document.getElementById("jsMainTitle")) {
		const mainTitleScroll = new MainTitleScroll();
	}
	
	//Google Maps
	if (typeof google === "object") {
		new AccessMap();
	}
	
});
