/***********************************


タブメニュー

************************************/
class AccessMap {
	constructor() {
		this.maps = [];
		this.mapCanvas = document.querySelectorAll("[data-map]");
		this.defMapOptions = {
			zoom: 17,
			center: {},
			overviewMapControl: true,
			scrollwheel: false,
			zoomControl: true,
			mapTypeControl: false,
			scaleControl: true,
			streetViewControl: false,
			rotateControl: false
		};
		this.mediaQuery = window.matchMedia(
			"screen and (max-width: " + SP_WIDTH + "px)"
		);
		if (this.mapCanvas) {
			this.init();
		}
	}
	init() {
		Array.prototype.slice.call(this.mapCanvas).forEach(element => {
			if( element.getAttribute("data-map-lat")){
				this.drawMap(element);
			}
		});
	}
	drawMap(mapEl) {
		let pos = new google.maps.LatLng(
			parseFloat(mapEl.getAttribute("data-map-lat")),
			parseFloat(mapEl.getAttribute("data-map-lng"))
		);
		let mapOption = JSON.parse(JSON.stringify(this.defMapOptions));
		mapOption.center = pos;
		let map = new google.maps.Map(mapEl, mapOption);
		this.maps.push(map);
		let _markerSize = this.mediaQuery.matches
			? new google.maps.Size(67 * 0.7, 85 * 0.7)
			: new google.maps.Size(67, 85);

		let markerOption = {
			map: map,
			position: pos,
			animation: google.maps.Animation.DROP,
			icon: {
				url: "/assets/images/access/pct-mappin.png",
				scaledSize: _markerSize
			}
		};
		let markers = new google.maps.Marker(markerOption);
	}
}
