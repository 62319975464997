/***********************************


下層ページ ページタイトルのスクロール


************************************/

class MainTitleScroll {
	constructor() {
		this.$titleContainer = $("#jsMainTitle");
		this.$title = this.$titleContainer.children(".js-mainTilteScroller");
		this.mediaQuery = window.matchMedia(
			"screen and (max-width: " + SP_WIDTH + "px)"
		);
		this.headerH = 0;
		this.endPos = 0;
		this.setPos();
		this.init();
	}
	init() {
		if (this.$title.length > 0) {
			this.$titleContainer.css("position","static");
			this.$title.css({
				position: "fixed",
				backgroundColor: "#fff",
				right: 0,
				left: 0,
				top: this.headerH + $(window).scrollTop() + "px",
				transform: "translate3d(0,0,0)"
			});
			this.mediaQuery.addListener(() => {
				this.setPos();
			});
			this.scrollLister();
			window.addEventListener("scroll", () => {
				this.scrollLister();
			});
		}
	}
	setPos() {
		let titleH = this.$title.height();
		this.$titleContainer.height(titleH);
		this.headerH = $("#Header").height();
		this.endPos = this.mediaQuery.matches ? 200 : 480;
		// console.log(this.headerH + "/" +this.endPos);
	}
	scrollLister() {
		let scTop = $(window).scrollTop();
		if (scTop <= this.endPos) {
			this.$title.css({
				position: "fixed",
				top: this.headerH + "px"
			});
		} else {
			this.$title.css({
				position: "absolute",
				top: this.headerH + this.endPos + "px"
			});
		}
	}
}
