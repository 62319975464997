/***********************************


トップスライダー


************************************/

class IndexSlider {
	constructor() {
		this.$slider = $("#jsIndexSlider");
		this.$lead = $("#jsIndexSliderLead");
		this.defSpeed = 5500;
		this.sliderRatio = 1280 / 780;
		this.sliderRatioSp = 750 / 1075;
		this.mainVideo = document.querySelectorAll("#jsIndexSlider video");
		this.isStart = false;
		this.mediaQuery = window.matchMedia(
			"screen and (max-width: " + SP_WIDTH + "px)"
		);
		this.init();
	}
	/**
	 * 初期化
	 */
	init() {
		this.checkWindowRetio();
		
		if (this.mainVideo && !this.mediaQuery.matches) {
			//PC
			if (this.canPlayVideo()) {
				//Video再生可
				this.loadVideo();
				this.mainVideo[0].addEventListener("canplaythrough", () => {
					if (!this.isStart) {
						this.initSlider();
					}
				});
			} else {
				if (!this.isStart) {
					this.initSlider();
				}
			}
		} else {
			//SP
			this.initSlider();
		}
		//リサイズ
		window.addEventListener("resize", () => {
			this.checkWindowRetio();
		});
		//メディアクエリ
		this.mediaQuery.addListener(() => {
			this.onMediaChange();
		});
	}
	/**
	 * videoが再生可能か判定
	 */
	canPlayVideo() {
		return document.createElement("video").canPlayType ? true : false;
	}
	/**
	 * スライダー初期化
	 */
	initSlider() {
		//スライダー初期化時
		this.$slider.on("init", slick => {
			this.isStart = true;
			this.currentVideoStart(0);
			this.$lead
				.find("[data-lead-id]")
				.eq(0)
				.addClass("is-current");
			this.$slider.removeClass("is-loading");
		});
		this.$slider.slick({
			dots: false,
			arrows: false,
			infinite: true,
			fade: true,
			speed: 1800,
			centerMode: false,
			autoplay: true,
			autoplaySpeed: 8000 //1枚目の時間
		});
		//スライド時
		this.$slider.on(
			"beforeChange",
			(event, slick, currentSlide, nextSlide) => {
				this.changeLead(currentSlide, nextSlide);
				this.currentVideoStart(nextSlide);
				if (nextSlide == 0) {
					this.$slider.slick("slickSetOption", "autoplaySpeed", 8000);
				} else {
					this.$slider.slick(
						"slickSetOption",
						"autoplaySpeed",
						this.defSpeed
					);
				}
			}
		);
	}
	/**
	 * すべてのVideをロード開始
	 */
	loadVideo() {
		Array.prototype.slice.call(this.mainVideo).forEach(video => {
			video.load();
		});
	}
	/**
	 * 現在のスライドのVideoを再生
	 * @param Int slideIndex slickのcurrentIndex
	 */
	currentVideoStart(slideIndex) {
		if (!this.mediaQuery.matches) {
			let $slide = this.$slider.find(`[data-slick-index=${slideIndex}]`);
			if ($slide.find("video").length > 0) {
				let video = $slide.find("video")[0];
				video.currentTime = 0;
				video.play();
			}
		}
	}
	/**
	 *リードの変更
	 * @param Int currentIndex slickのcurrentIndex
	 * @param Int nextIndex slickのnextIndex
	 */
	changeLead(currentIndex, nextIndex) {
		let currentLeadId = this.$slider
			.find(`[data-slick-index=${currentIndex}]`)
			.attr("data-lead-id");
		let nextLeadId = this.$slider
			.find(`[data-slick-index=${nextIndex}]`)
			.attr("data-lead-id");
		if (currentLeadId !== nextLeadId) {
			this.$lead.find("[data-lead-id]").removeClass("is-current");
			this.$lead
				.find("[data-lead-id=" + nextLeadId + "]")
				.addClass("is-current");
		}
	}
	/**
	 * メディアクエリの変更時
	 */
	onMediaChange() {
		if (!this.mediaQuery.matches) {
			this.loadVideo();
		} else {
			Array.prototype.slice.call(this.mainVideo).forEach(video => {
				video.pause();
			});
		}
	}
	/**
	 * Windowがvideoの比率より縦長の場合class追加
	 */
	checkWindowRetio() {
		let windowRetio = window.innerWidth / window.innerHeight;
		let ratio = this.mediaQuery.matches
			? this.sliderRatioSp
			: this.sliderRatio;
		if (windowRetio < ratio) {
			this.$slider.addClass("is-vertical");
		} else {
			this.$slider.removeClass("is-vertical");
		}
	}
}
